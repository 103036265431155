.nav-menu-container {
    display: flex;
    width: auto;
    margin-top: 8px;
    margin-left: 8px;
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: center;
}

.nav-menu-title {
    font-weight: bold;
    font-size: 20px;
}

.nav-menu-lng>.ant-image {
    margin-left: 8px;
}

.ant-menu-title-content {
    font-size: 16px !important;
}

.account-btn {
    font-size: 20px;
}

.account-item-btn {
    font-size: 20px;
    color: var(--active-color) !important;
}

/*TODO: ant- convert to special style*/
.ant-dropdown-menu {
    /*width: 323px !important;*/
    border-radius: 0 !important;
    /*padding: 16px 40px !important;*/
}

.ant-dropdown-menu-item {
    padding: 8px 12px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active {
    background-color: transparent !important;
}

@media (min-width: 300px) {
    .ant-menu-title-content {
        font-size: 12px !important;
    }
}

@media (min-width: 744px) {
    .ant-menu-title-content {
        font-size: 16px !important;
    }
}

@media (min-width: 1400px) {
    .ant-menu-title-content {
        font-size: 16px !important;
    }
}

