/*.header-body {
    width: 1440px;
    margin: auto;
}*/

.header {
    background-color: #E8A96B !important;
}



@media (min-width: 300px) {
    .search-input {
        width: 300px;
    }
}

@media (min-width: 744px) {
    .search-input {
        width: 720px;
    }
}

@media (min-width: 1400px) {
    .search-input {
        width: 1400px;
    }
}