.home-groups {
    margin-top: 32px;
}

.slick-list {
    margin: 0 -8px !important;
}

.slick-track {
    display: flex !important;
    padding-top: 12px;
    padding-bottom: 12px;
}

.slick-slide {
    height: inherit !important;
    width: inherit !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
}

.slick-slide > div {
    height: 100% !important;
    width: 100% !important;
}

.slick-prev::before,
.slick-next::before,
.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
    display: none;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
    font-size: 32px;
    width: 32px;
    height: 32px;
    left: 10px;
    z-index: 2;
    color: var(--primary-color);
    background: #ffffff;
    border-radius: 50px;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
    font-size: 32px;
    width: 32px;
    height: 32px;
    right: 10px;
    z-index: 2;
    color: var(--primary-color);
    background: #ffffff;
    border-radius: 50px;
}

.home-group-title {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 300px;
    white-space: nowrap;
}

.card-shadow {
    box-shadow: 4px 4px 4px #D9D9D9;
}