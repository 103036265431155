.article-title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.article-title>div {
    margin-right: 16px;
}

.article-related-title {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 12px;
}

.article-related-link {
    padding-bottom: 12px;
}

.article-side {
    padding-top: 16px;
}

.article-body {
    padding: 16px;
}

#mw-container div.mw-no-notifications div.subheading p {
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.confluenceTable, table {
    border-collapse: collapse;
}

td > p {
    margin: 0;
}

.confluenceTh,
.confluenceTd,
td, th {
    border: 1px solid #ddd;
    padding: 7px 10px; /* CONFDEV-13618: line-height will add up 3 pixels, so we have a 10px top padding for text. Images will remain with 7px top margin though (see thread in Stash) */
    vertical-align: top;
    text-align: left;
    min-width: 8px; /* CONF-39943: set table cell min-width to which cursor can be focused */
}

/* Lists in tables */
.confluenceTable ol,
.confluenceTable ul {
    margin-left: 0;
    padding-left: 22px; /* CONFDEV-12589: default left padding is far too wide */
}

img {
    max-width: 100%;
}

.btn-img {
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0 !important;
    margin: 4px;
}