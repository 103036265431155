@import './ant-custom.css';

:root {
    --default-color: #E8A96B;
    --primary-color: #0D3D35;
    --hover-color: #0D3D35;
    --block-content-backround-img: '';
    --active-color: #0D3D35;
    --inactive-color: #ff0000;
}

body {
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0;
    overflow-y: scroll;
}

.content-container {
    background-image: var(--block-content-backround-img);
    background-size: cover;
    min-height: calc(100vh - 244px) !important;
}

.btn-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 16px;
    margin-top: 16px;
}

.block-content {
    margin: auto;
    padding-bottom: 32px;
}

.active {
    color: var(--active-color);
}

.inactive {
    color: var(--inactive-color);
}



@media (min-width: 300px) {
    .content-container {
        width: 300px;
        margin: auto;
        padding: 24px;
        padding-top: 0px;
    }
}

@media (min-width: 744px) {
    .content-container {
        width: 720px;
        margin: auto;
        padding: 24px;
        padding-top: 0px;
    }
}

@media (min-width: 1400px) {
    .content-container {
        width: 1400px;
        margin: auto;
        padding: 24px;
        padding-top: 0px;
    }
}