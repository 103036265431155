.auth-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
    text-align: center;
}

.auth-sign-up {
    width: 100%;
}

.auth-btn {
    flex: 1;
}

.auth-prompt {
    font-size: 12px;
    text-align: center;
}