.article-card {
    display: flex;
    flex-direction: row;
    min-height: 80px;
    margin: 8px;
}

.article-card-content {
    flex-direction: column;
    margin-left: 8px;
}

.article-card-title {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.article-card-description {
    font-size: 14px;
    margin-bottom: 8px;
}

.article-card-subitem {
    font-size: 12px;
    padding-left: 8px;
    cursor: pointer;
}