a, .ant-btn-link, .ant-menu-item {
    color: black;
}

.ant-menu-horizontal {
    border: none;
    line-height: 32px;
    padding-inline-start: 0px;
}

.ant-btn-link:not(:disabled):hover {
    font-weight: 700;
}

.ant-menu-item-active,
.ant-menu-item-selected {
    font-weight: 700;
    color: white !important;
}

.ant-menu-item-active::after,
.ant-menu-item-selected::after {
    display: none;
}

.ant-card {
    height: 100%;
    border-radius: 4px;
    border: 1px solid var(--default-color);
    /*border: 0px;*/
    
}

.ant-card-head {
    min-height: 0 !important;
    padding: 10px !important;
    background: #ffffff !important;
    border-bottom: none !important;
}

.ant-card-head-title {
    font-weight: 700;
    font-size: 18px;
    /*color: var(--primary-color);*/
}

.ant-card-cover img {
    border-radius: 0 !important;
    margin: 0;
}

.ant-card-body {
    padding: 9px !important;
    font-size: 14px;
}

.ant-layout-footer {
    min-height: 64px;
    background: var(--default-color) !important;
    color: var(--primary-color) !important;
    text-align: center;
    width: 100%;
}

.ant-layout {
    background-color: transparent !important;
}

.flexible-card .ant-card-body {
    flex-grow: 1;
}

.ant-card-actions {
    border: none !important;
}

.ant-card-actions > li {
    margin: 0 !important;
}

.ant-modal-content {
    border-radius: 0 !important;
    padding: 16px 40px !important;
}

.ant-modal-title {
    font-weight: 400 !important;
    font-size: 18px !important;
}

.ant-modal-body {
    font-size: 14px !important;
}

.ant-btn-default {
    color: var(--primary-color);
    border-radius: 4px;
}

.ant-form-item {
    margin-bottom: 16px !important;
}

.ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper
    .ant-table-thead
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0;
}

.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
}

.ant-table-wrapper .ant-table-summary > tr > th,
.ant-table-wrapper .ant-table-summary > tr > td {
    border-bottom: none;
    background-color: #f5f5f5;
}

.ant-table-wrapper .ant-table-column-sorter-up,
.ant-table-wrapper .ant-table-column-sorter-down {
    color: var(--default-color);
}

.ant-table-wrapper .ant-table-column-sorter-up.active,
.ant-table-wrapper .ant-table-column-sorter-down.active {
    color: var(--primary-color);
}

.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr.ant-table-row:hover > td:first-child,
.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr > td.ant-table-cell-row-hover:first-child,
.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr.ant-table-row.ant-table-row-selected > td:first-child {
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
}

.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr.ant-table-row:hover > td:last-child,
.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr > td.ant-table-cell-row-hover:last-child,
.ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr.ant-table-row.ant-table-row-selected > td:last-child {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
}

.ant-table-thead .ant-table-cell {
    border-top: none !important;
    background-color: #ffffff !important;
}

.ant-table-tbody .ant-table-row:nth-child(even) {
    background-color: #f5f5f5;
}

.ant-table-cell {
    border-top: 1px solid #656565f5 !important;
}

.ant-table-column-sort {
    background-color: transparent !important;
}

.ant-table-cell-row-hover {
    background-color: #fafafa !important;
}

.ant-card-meta-description {
    color: rgba(0, 0, 0, 1) !important;
}

.ant-space {
    color: #ffffff !important;
}

.ant-card-hoverable:hover .ant-card-head-title {
    font-weight: bold;
}

.ant-btn {
    font-weight: 700;
}

@media (min-width: 0px) {
    .ant-menu-item {
        padding-inline: 4px !important;
    }
}

@media (min-width: 300px) {
    .ant-btn-default {
        background: var(--hover-color);
        border: 1px solid var(--hover-color);
        color: white;
    }

    .ant-layout-footer {
        padding: 9px !important;
    }

    .ant-menu-item {
        padding-inline: 8px !important;
    }

    .ant-modal-content {
        padding: 8px 8px !important;
    }
    .ant-form-item {
        margin-bottom: 8px !important;
    }
}

@media (min-width: 744px) {
    .ant-layout-footer {
        padding: 23px !important;
    }
    .ant-btn {
        font-size: 16px;
    }
    .ant-menu-horizontal {
        padding-inline-end: 16px;
    }    
}

@media (min-width: 1400px) {
    .ant-btn-default {
        background: transparent;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
    }

    .ant-btn-default:hover {
        background: var(--hover-color);
        border: 1px solid var(--hover-color) !important;
    }
    .ant-menu-horizontal {
        padding-inline-end: 16px;
    }        
}
